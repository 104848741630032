<template>
  <div class="flex flex-row w-full items-center cursor-pointer" 
   :class="source.selected && 'selected'" style="min-height: 50px;" @click="toggleSelection(source)">
    <div class="flex justify-center w-1/12">
      <input type="checkbox" v-model="source.selected" :value="source.selected" class="cursor-pointer" />
    </div>
    <div class="whitespace-no-wrap table-cell text-left w-10">
      <a target="_blank" :href="`https://twitter.com/${source.username}/status/${source.id}`">
        {{ source.time }}
      </a>
    </div>
    <div :title="source.status" class="opacity-50 table-cell w-1/2" dir="auto">
      <div class="truncate" style="max-width: 99%;">{{ source.status }}</div>
    </div>
    <div class="text-center opacity-50 table-cell w-10">{{ source.favoriteCount }}</div>
    <div class="text-center opacity-50 table-cell w-10">
      {{ source.retweetCount }}
    </div>
    <div class="flex justify-center w-10">
      <base-switch classes="cursor-pointer" v-model="source.isFavorite"></base-switch>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'item-component',
    props: {
      index: {
        type: Number,
      },
      source: {
        type: Object,
        default() {
          return {};
        },
      },
    },
    methods: {
      toggleSelection(source) {
        source.selected = !source.selected;
      }
    }
  };
</script>
<style lang="scss" scoped>
  .w-10 {
    width: 10%;
  }
  .selected {
    @apply bg-divider;
    box-shadow: 0 1px 0 var(--color-divider);
  }
</style>
