<template>
  <div class="pb-5">
    <h1 class="mb-6 font-weight-bold text-2xl">Twitter Archive Uploader</h1>

    <div class="card tweet flex flex-row p-3 shadow-lg">
      <p class="mb-4">
        Use this tool to upload the archived tweets from your
        <strong><code>tweets.js</code></strong> file.
      </p>

      <p class="mb-4">Retweets and replies will not be included.</p>

      <div v-if="tweets.length === 0">
        <collapse>
          <collapse-item
            title="Read instructions"
            class="flex flex-col items-start"
            buttonType="primary"
          >
            <div class="p-4 pb-0">
              <h3>Step 1</h3>
              <ul class="list-disc">
                <li>
                  If you don't have it already,
                  <a href="https://www.twitter.com/settings/download_your_data" target="_blank">
                    click here
                  </a>
                  to request your Twitter Archive.
                </li>
              </ul>
              <hr />
              <br />
              <h3>Step 2</h3>
              <ul class="list-disc">
                <li>Your Twitter Archive will take 24 hours to be created.</li>
                <li>You will receive an email from Twitter with a link to download the Archive.</li>
                <li>Download it.</li>
              </ul>
              <hr />
              <br />
              <h3>Step 3</h3>
              <ul class="list-disc">
                <li>Once you download the zip file, extract it.</li>
                <li>
                  Go to the <strong><code>data</code></strong> folder.
                </li>
                <li>
                  Scroll down to find a file named <strong><code>tweet.js</code></strong
                  >.
                </li>
                <li>That is the file you must upload here.</li>
              </ul>
            </div>
          </collapse-item>
        </collapse>
      </div>

      <div class="container-fluid mt-6" v-if="tweets.length === 0">
        <dropzone-file-upload v-model="tweetsFile" :options="uploadOptions"> </dropzone-file-upload>
      </div>
    </div>
    <div class="flex flex-col" v-if="tweets.length > 0">
      <base-alert type="warning" radius="rounded-lg" v-if="userProfile.customerStatus === 'none'">
        You are on the free plan and only have {{ emptySlotCount }} slots left. You can pick up to
        {{ emptySlotCount }} tweets.
      </base-alert>
      <div class="mt-2 pt-6">
        <div class="mb-4 flex flex-row justify-between">
          <div class="flex flex-row items-center">
            <base-switch v-model="showOnlyLast6Months"></base-switch>
            <p class="ml-2">Show tweets from last 6 months only.</p>
          </div>
          <div class="flex flex-row items-center">
            <inline-svg src="/img/icons/tick.svg" />
            <p class="ml-2">{{ parsedTweetsLength }} tweets loaded.</p>
          </div>
        </div>
        <div class="card tweet rounded relative mt-2 flex w-full flex-col shadow-lg">
          <div class="flex w-full flex-row items-stretch">
            <div class="table-header w-1/12">
              <input type="checkbox" v-model="importAllTweets" class="cursor-pointer" />
            </div>
            <div class="table-header align-header-text-left w-10">Date</div>
            <div class="table-header align-header-text-left w-1/2">Tweet</div>
            <div class="table-header w-10">Likes</div>
            <div class="table-header w-10">Retweets</div>
            <div class="table-header" style="width: 14%">Set as Evergreen</div>
          </div>
          <virtual-list
            class="list"
            :data-key="'id'"
            :data-sources="filteredTweets"
            :estimate-size="50"
            :data-component="itemComponent"
            :keeps="40"
          />
          <div class="rounded-b sticky bottom-0 bg-sidebar p-3">
            <base-button
              type="primary"
              class="btn text primary mr-auto"
              @click="addTweetsToQueue()"
              :class="filteredTweets.length > 0 ? '' : 'disabled'"
              :disabled="
                filteredTweets.length === 0 ||
                isSaving ||
                !filteredTweets.some((tweet) => tweet.selected)
              "
              :loading="isSaving"
            >
              <span>Add to Queue</span>
            </base-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters, mapState } from 'vuex';
  import Collapse from '@/components/Collapse/Collapse';
  import CollapseItem from '@/components/Collapse/CollapseItem';
  import DropzoneFileUpload from '@/components/Inputs/DropzoneFileUpload';
  import dao from '@/dao';
  import { decodeTweetText } from '@/util/decodeTweetText';
  import lodash from 'lodash';
  import moment from 'moment';
  import 'moment-timezone';
  import { Schedule } from '@/models/Schedule';
  import { ScheduleForSettings } from '@/models/ScheduleForSettings';
  import ImportedTweet from './ImportedTweet.vue';
  import VirtualList from 'vue-virtual-scroll-list';
  import { buildPostFromFirestore } from '../../../util/buildPostFromFirestore';
  import SwalModalMixin from '../../Mixins/SwalModalMixin.vue';
  const fb = require('@/firebase');
  import firebase from 'firebase/compat/app';
  import 'firebase/compat/firestore';

  export default {
    name: 'twitter-archive-uploader',
    components: {
      Collapse,
      CollapseItem,
      DropzoneFileUpload,
      VirtualList,
    },
    computed: {
      ...mapGetters({ currentUser: 'getCurrentUser', userProfile: 'getUserProfile' }),
      ...mapState(['schedule']),
      emptySlotCount() {
        const allSlots = lodash.flatten(Object.values(this.schedule.getThreadsByDate()));
        return allSlots.filter((slot) => slot.isEmpty()).length;
      },
      timezone() {
        return lodash.get(this, 'userProfile.timezone', moment.tz.guess());
      },
      parsedTweetsLength() {
        return this.tweets.length;
      },
      filteredTweets() {
        const now = moment.tz(this.timezone);
        const sixMonthsAgo = now.clone().subtract('6', 'months');
        return this.showOnlyLast6Months
          ? this.tweets.filter((tweet) => {
              const createdAtDate = new Date(tweet.createdAt);
              return moment.tz(createdAtDate, this.timezone).isAfter(sixMonthsAgo);
            })
          : this.tweets;
      },
    },
    data() {
      return {
        tweetsFile: [],
        isSaving: false,
        importAllTweets: false,
        tweets: [],
        uploadOptions: {
          dictDefaultMessage: 'Drop your tweets.js file here to upload.',
          acceptedFiles: '.js',
          autoProcessQueue: false,
        },
        itemComponent: ImportedTweet,
        showOnlyLast6Months: false,
      };
    },
    methods: {
      timestampToDate(timestamp) {
        const date = new Date(timestamp);
        return moment.tz(date, this.timezone).format('MMM D Y');
      },
      async addTweetsToQueue() {
        const tweetsToAdd = this.filteredTweets.filter((tweet) => tweet.selected);
        const tweetsToAddIds = tweetsToAdd.map((tweet) => tweet.id);

        if (this.emptySlotCount === 0) {
          this.swalModal({
            title: 'Queue full',
            text: `Sorry, you don't have any empty slots in your queue.`,
            type: 'error',
          });
          return;
        }

        if (this.emptySlotCount < tweetsToAdd.length) {
          this.swalModal({
            title: 'Not enough empty slots',
            html: `You don't have enough empty slots.<br>
              You can only schedule up to ${this.emptySlotCount} tweets.`,
            type: 'error',
          });
          return;
        }

        try {
          this.isSaving = true;

          const user = fb.usersCollection.doc(this.userProfile.uid);

          const scheduledThreads = (
            await fb.threadsCollection
              .where('user', '==', user)
              .where('time', '>=', new Date())
              .get()
          ).docs;

          const threads = scheduledThreads.map((doc) => buildPostFromFirestore(doc, this.timezone));

          const userSchedule = (await user.get()).data().schedule;

          const schedule = new Schedule(
            365,
            this.timezone,
            threads,
            new ScheduleForSettings(userSchedule)
          );

          const emptySlots = schedule.getAllSlots().filter((s) => s.isEmpty());

          const newThreads = lodash.take(tweetsToAdd, emptySlots.length).map((tweet) => {
            const tweets = [
              {
                status: tweet.status,
                count: 0,
                media: null,
                published: false,
                retweetCount: 0,
                favoriteCount: 0,
              },
            ];
            const time = emptySlots.shift();
            const timeToUse = moment.tz(time.time, this.timezone).toDate();

            return {
              scheduled: false,
              time: timeToUse,
              tweets,
              user,
              created_at: firebase.firestore.FieldValue.serverTimestamp(),
              tweetIds: [],
              deleted: false,
              type: 'post',
              publishingError: null,
              isFavorite: tweet.isFavorite,
              postNow: false,
              conditionalRetweetsConditions: null,
              autoplug: null,
              source: null,
              writer: null,
              growthProgram: null,
              shareOnInstagram: null,
              linkedIn: null,
              delayBetweenTweets: null,
              categories: [],
              recurrentPostRef: null,
              replyToTweetId: null,
              replyToTweetInfo: null,
              facebook: null,
              tweetsCount: 1,
              instagramCaption: null,
              isCloned: false,
              isRecurrentPost: false,
            };
          });

          if (tweetsToAdd.length !== newThreads.length) {
            this.$notify({
              message: `Only ${newThreads.length} tweets were imported.`,
              type: 'warning',
            });
          }

          // Chunks have to be divided by 250 instead of the usual 500 because calls to serverTimestamp() count as an additional write op
          const threadsGroups = lodash.chunk(newThreads, 250);
          const saveBatches = threadsGroups.map((threads) =>
            dao.saveThreadsInBatchesToFirestore(threads, this.userProfile.uid)
          );
          await Promise.all(saveBatches);

          this.$notify({
            message: `You have successfully scheduled ${newThreads.length} imported tweets!`,
            type: 'success',
          });

          this.$eventStore.scheduleTweetsFromTwitterArchive();

          // Remove already imported tweets from view
          this.tweets = this.filteredTweets.filter((tweet) => !tweetsToAddIds.includes(tweet.id));
        } catch (error) {
          console.error(error);
          this.swalModal({
            title: 'Error',
            text: `Sorry, an error has occurred while trying to schedule your imported tweets. Please try again.`,
            type: 'error',
          });
        } finally {
          this.isSaving = false;
          this.showOnlyLast6Months = false;
        }
      },
    },
    mounted() {
      this.$eventStore.visitToolboxTwitterArchiveUploaderPage();
    },
    watch: {
      tweetsFile(newValue) {
        const self = this;
        if (newValue.length === 0) return;

        const file = newValue[0];
        const fileType = file.type;
        const fileExtension = file.name.split('.').pop();

        if (!['', 'text/javascript'].includes(fileType) && fileExtension !== 'js') {
          this.swalModal({
            title: 'Error',
            text: 'Not a valid JavaScript file.',
            type: 'error',
          });
          return;
        }

        // Read File on memory
        const reader = new FileReader();
        reader.addEventListener('load', (event) => {
          const result = event.target.result;

          if (!result.includes('window.YTD.tweets.part0')) {
            this.swalModal({
              title: 'Error',
              text: 'Not a valid tweets.js archive file. Please make sure you did not modify the file after downloading it from Twitter.',
              type: 'error',
            });
            return;
          }

          let parsedTweets = [];

          try {
            // Twitter archive format starts with: `window.YTD.tweets.part0 = [{`
            parsedTweets = JSON.parse(result.slice(25)).map((t) => t.tweet);

            // Filter replies
            parsedTweets = parsedTweets.filter((tweet) => !tweet.in_reply_to_status_id);

            // Filter RTs
            parsedTweets = parsedTweets.filter((tweet) => !tweet.full_text.includes('RT @'));
          } catch {
            parsedTweets = JSON.parse(result);
          }

          this.tweets = parsedTweets
            .map((tweet) => {
              return {
                selected: false,
                status: decodeTweetText(tweet.full_text),
                id: tweet.id,
                favoriteCount: tweet.favorite_count,
                retweetCount: tweet.retweet_count,
                time: self.timestampToDate(tweet.created_at),
                createdAt: tweet.created_at,
                isFavorite: false,
                username: this.userProfile.username,
              };
            })
            .sort((a, b) => b.favoriteCount - a.favoriteCount);

          this.$eventStore.uploadTwitterArchive();
        });

        reader.readAsText(file);
      },
      importAllTweets(val) {
        if (this.filteredTweets.length === 0 || this.isSaving) return;
        this.tweets = this.filteredTweets.map((tweet) => ({ ...tweet, selected: val }));
      },
    },
    mixins: [SwalModalMixin],
  };
</script>
<style lang="scss" scoped>
  .table-header {
    align-items: center;
    text-align: center;
    position: sticky;
    top: 0;
    padding-top: 10px;
    padding-bottom: 10px;
    color: var(--color-primary);
    line-height: 1.25;
    font-family: Greycliff CF Medium;
    font-weight: bold;
    box-shadow: 0 1px 0 var(--color-divider);
  }
  .group > div {
    box-shadow: 0 1px 0 var(--color-divider);
  }
  .list {
    width: 100%;
    height: 100%;
    max-height: 50vh;
    border-radius: 3px;
    overflow-y: auto;
  }

  .align-header-text-left {
    text-align: left;
  }

  .w-10 {
    width: 10%;
  }
</style>
